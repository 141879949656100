<template>
  <fieldset :class="{ box: !isViewModeCreate }">
    <page-title
      :title="$t('page.beneficiary.title')"
      @edit="$emit('edit')"
    >
      <p class="has-text-justified">
        {{ $t('page.beneficiary.description') }}
      </p>
    </page-title>
    <div
      v-for="(beneficiary, index) in model"
      :key="index"
      class="box"
    >
      <button
        v-if="isViewModeCreate && model.length > 1"
        role="button"
        type="button"
        class="delete"
        tabindex="100"
        @click="removeBeneficiary(index)"
      >
      </button>
      <h3>{{ $t('page.beneficiary.section') + ' ' + (index + 1) }}</h3>
      <div class="columns">
        <div class="column is-half">
          <b-field
            :label="getFieldLabel('firstname')"
            :type="getFieldType('firstname', index)"
            :message="getFieldMessage('firstname', index)"
          >
            <b-input
              v-if="isFieldEditable(`$.beneficiary[${index}].firstname`)"
              v-model="beneficiary.firstname"
              @blur="validateFirstname(index)"
              custom-class="page-focus-first"
              :name="`fname${index}`"
              autocomplete="given-name"
            >
            </b-input>
            <span v-else>{{ beneficiary.firstname }}</span>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field
            :label="getFieldLabel('lastname')"
            :type="getFieldType('lastname', index)"
            :message="getFieldMessage('lastname', index)"
          >
            <b-input
              v-if="isFieldEditable(`$.beneficiary[${index}].lastname`)"
              v-model="beneficiary.lastname"
              @blur="validateLastname(index)"
              :name="`lname${index}`"
              autocomplete="family-name"
            >
            </b-input>
            <span v-else>{{ beneficiary.lastname }}</span>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field
            :label="getFieldLabel('address')"
            :type="getFieldType('address', index)"
            :message="getFieldMessage('address', index)"
          >
            <b-input
              v-if="isFieldEditable(`$.beneficiary[${index}].address`)"
              v-model="beneficiary.address"
              @blur="validateAddress(index)"
              :name="`address${index}`"
              autocomplete="address-line1"
            >
            </b-input>
            <span v-else>{{ beneficiary.address }}</span>
          </b-field>
        </div>
      </div>
      <div
        v-if="isViewModeCreate || beneficiary.useProfessionalAddress"
        class="columns"
      >
        <div class="column">
          <b-checkbox
            v-if="isViewModeCreate"
            v-model="beneficiary.useProfessionalAddress"
            class="mb-3"
          >
            {{ $t('page.beneficiary.useProfessionalAddress') }}
          </b-checkbox>
          <b-field
            v-if="beneficiary.useProfessionalAddress"
            :label="getFieldLabel('professionalAddress')"
            :type="getFieldType('professionalAddress', index)"
            :message="getFieldMessage('professionalAddress', index)"
          >
            <b-input
              v-if="isFieldEditable(`$.beneficiary[${index}].professionalAddress`)"
              v-model="beneficiary.professionalAddress"
              @blur="validateProfessionalAddress(index)"
              :name="`professionalAddress${index}`"
              autocomplete="address-line1"
            >
            </b-input>
            <span v-else>{{ beneficiary.professionalAddress }}</span>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <b-field
            :label="getFieldLabel('birthdate')"
            :type="getFieldType('birthdate', index)"
            :message="getFieldMessage('birthdate', index)"
          >
            <b-datepicker
              v-if="isFieldEditable(`$.beneficiary[${index}].birthdate`)"
              v-model="beneficiary.birthdate"
              :max-date="atLeastEighteenYearsOld"
              :date-formatter="formatDateWithSlashes"
              icon="calendar-day"
              trap-focus
              @blur="validateBirthdate(index)"
            >
            </b-datepicker>
            <span v-else>{{ formatBirthdate(beneficiary.birthdate) }}</span>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field
            :label="getFieldLabel('startDate')"
            :type="getFieldType('startDate', index)"
            :message="getFieldMessage('startDate', index)"
          >
            <b-datepicker
              v-if="isFieldEditable(`$.beneficiary[${index}].startDate`)"
              v-model="beneficiary.startDate"
              :date-formatter="formatDateWithSlashes"
              icon="calendar-day"
              trap-focus
              @blur="validateStartDate(index)"
            >
            </b-datepicker>
            <span v-else>{{ formatBirthdate(beneficiary.startDate) }}</span>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <p class="has-text-weight-bold">{{ getFieldLabel('qualification') }}</p>
          <div
            v-if="isViewModeCreate"
            class="content"
          >
            <ul>
              <li>Sélectionnez la ou les situations applicables au bénéficiaire ultime et précisez le pourcentage correspondant, s'il y a lieu.</li>
              <li>Si la dernière situation s'applique au bénéficiaire ultime, vous devez sélectionner uniquement cette situation.</li>
              <li>Notez que vous ne pouvez pas sélectionner à la fois la première et la deuxième situation.</li>
            </ul>
          </div>
          <b-field class="mt-3 mb-2">
            <b-checkbox
              v-model="beneficiary.qualificationByVote"
              @input="beneficiary.qualificationByVotePercentage = ''"
              :disabled="!isViewModeCreate"
            >
              Personne qui détient ou contrôle un nombre d'actions, de parts ou d'unités lui donnant la faculté d'exercer 25 % ou plus des droits de vote, ou qui en bénéficie.
            </b-checkbox>
          </b-field>
          <div class="ml-6">
            <b-field class="mb-1">
              <b-radio
                v-model="beneficiary.qualificationByVotePercentage"
                native-value="25to50"
                :name="`qualificationByVotePercentage${index}`"
                :disabled="!beneficiary.qualificationByVote || !isViewModeCreate"
              >
                25% à 50%
              </b-radio>
            </b-field>
            <b-field class="mb-1">
              <b-radio
                v-model="beneficiary.qualificationByVotePercentage"
                native-value="50to75"
                :name="`qualificationByVotePercentage${index}`"
                :disabled="!beneficiary.qualificationByVote || !isViewModeCreate"
              >
                Plus de 50 % jusqu'à 75 %
              </b-radio>
            </b-field>
            <b-field>
              <b-radio
                v-model="beneficiary.qualificationByVotePercentage"
                native-value="75"
                :name="`qualificationByVotePercentage${index}`"
                :disabled="!beneficiary.qualificationByVote || !isViewModeCreate"
              >
                Plus de 75 %
              </b-radio>
            </b-field>
          </div>
          <b-field class="mt-5">
            <b-checkbox
              v-model="beneficiary.qualificationByContract"
              :disabled="!isViewModeCreate"
            >
              Personne qui détient des actions, des parts ou des unités de l'assujetti, ou qui en bénéficie, ayant convenu d'exercer conjointement ses droits de vote avec d'autres personnes et que cette entente a pour effet de leur conférer la faculté d'exercer ensemble 25 % ou plus de ces droits, ou personne qui contrôle des actions, des parts ou des unités de l'assujetti à l'égard desquelles une entité a conclu une telle entente.
            </b-checkbox>
          </b-field>
          <b-field class="mt-5 mb-2">
            <b-checkbox
              v-model="beneficiary.qualificationByOwnership"
              @input="beneficiary.qualificationByOwnershipPercentage = ''"
              :disabled="!isViewModeCreate"
            >
              Personne qui détient ou contrôle un nombre d'actions, de parts ou d'unités lui donnant la faculté d'exercer 25 % ou plus des droits de vote, ou qui en bénéficie.
            </b-checkbox>
          </b-field>
          <div class="ml-6">
            <b-field class="mb-1">
              <b-radio
                v-model="beneficiary.qualificationByOwnershipPercentage"
                native-value="25to50"
                :name="`qualificationByOwnershipPercentage${index}`"
                :disabled="!beneficiary.qualificationByOwnership || !isViewModeCreate"
              >
                25% à 50%
              </b-radio>
            </b-field>
            <b-field class="mb-1">
              <b-radio
                v-model="beneficiary.qualificationByOwnershipPercentage"
                native-value="50to75"
                :name="`qualificationByOwnershipPercentage${index}`"
                :disabled="!beneficiary.qualificationByOwnership || !isViewModeCreate"
              >
                Plus de 50 % jusqu'à 75 %
              </b-radio>
            </b-field>
            <b-field>
              <b-radio
                v-model="beneficiary.qualificationByOwnershipPercentage"
                native-value="75"
                :name="`qualificationByOwnershipPercentage${index}`"
                :disabled="!beneficiary.qualificationByOwnership || !isViewModeCreate"
              >
                Plus de 75 %
              </b-radio>
            </b-field>
          </div>
          <b-field class="mt-5">
            <b-checkbox
              v-model="beneficiary.qualificationByInfluence"
              :disabled="!isViewModeCreate"
            >
              Personne qui a une influence directe ou indirecte telle que, si elle était exercée, il en résulterait un contrôle de fait de l'assujetti.
            </b-checkbox>
          </b-field>
          <b-field class="mt-5">
            <b-checkbox
              v-model="beneficiary.qualificationByException"
              :disabled="!isViewModeCreate"
            >
              La personne répond à la définition de bénéficiaire ultime, mais aucune des situations énoncées ci-dessus ne lui est applicable.
            </b-checkbox>
          </b-field>
          <b-field>
            <b-input
              v-if="isFieldEditable(`$.beneficiary[${index}].qualification`) && beneficiary.qualificationByException"
              v-model="beneficiary.qualification"
              maxlength="2000"
              type="textarea"
              @blur="validateQualification(index)"
              autocomplete="off"
            >
            </b-input>
            <span v-else>{{ beneficiary.qualification }}</span>
          </b-field>
        </div>
      </div>
    </div>
    <b-field class="has-text-centered">
      <b-button
        v-if="isViewModeCreate && model.length < 20"
        @click="addBeneficiary"
        type="is-text is-anchor"
      >
        {{ $t('page.beneficiary.add') }}
      </b-button>
    </b-field>
  </fieldset>
</template>

<script>
import page from '@/mixins/page';
import date from '@/mixins/date';

export default {
  name: 'PageBeneficiary',
  mixins: [
    page('beneficiary'),
    date
  ],
  mounted () {
    if (this.model.length === 0) {
      this.prefillBeneficiaries();
    }
  },
  methods: {
    addBeneficiary () {
      this.model.push({
        address: '',
        birthdate: undefined,
        firstname: '',
        lastname: '',
        professionalAddress: '',
        startDate: undefined,
        useProfessionalAddress: false,
        qualificationByVote: false,
        qualificationByContract: false,
        qualificationByOwnership: false,
        qualificationByInfluence: false,
        qualificationByException: false
      });
    },
    prefillBeneficiaries () {
      if (this.storedModel.neq.actionnaire) {
        const beneficiaries = [];
        if (this.storedModel.neq.actionnaire?.premier)
          beneficiaries.push(this.storedModel.neq.actionnaire.premier);
        if (this.storedModel.neq.actionnaire?.deuxieme)
          beneficiaries.push(this.storedModel.neq.actionnaire.deuxieme);
        if (this.storedModel.neq.actionnaire?.troisieme)
          beneficiaries.push(this.storedModel.neq.actionnaire.troisieme);

        beneficiaries.forEach(beneficiary => {
          if (beneficiary.typePersonne === 'morale') {
            this.model.push({
              address: beneficiary.adresse,
              birthdate: undefined,
              firstname: beneficiary.nom,
              lastname: '',
              professionalAddress: '',
              startDate: undefined,
              useProfessionalAddress: false,
              qualificationByVote: false,
              qualificationByContract: false,
              qualificationByOwnership: false,
              qualificationByInfluence: false,
              qualificationByException: false
            });
          } else {
            this.model.push({
              address: beneficiary.adresse,
              birthdate: undefined,
              firstname: beneficiary.prenom,
              lastname: beneficiary.nom,
              professionalAddress: '',
              startDate: undefined,
              useProfessionalAddress: false,
              qualificationByVote: false,
              qualificationByContract: false,
              qualificationByOwnership: false,
              qualificationByInfluence: false,
              qualificationByException: false
            });
          }
        });
      }
    },
    removeBeneficiary (index) {
      this.model.splice(index, 1);
      this.clearErrors();
    },
    validateAddress (index) {
      this.validateNotEmpty(`address${index}`, this.model[index].address);
    },
    validateBirthdate (index) {
      if (!this.model[index].birthdate)
        this.addError(`birthdate${index}`);
      else
        this.removeError(`birthdate${index}`);
    },
    validateFirstname (index) {
      this.validateNotEmpty(`firstname${index}`, this.model[index].firstname);
    },
    validateLastname (index) {
      this.validateNotEmpty(`lastname${index}`, this.model[index].lastname);
    },
    validateProfessionalAddress (index) {
      if (this.model[index].useProfessionalAddress && this.model[index].professionalAddress.length < 1)
        this.addError(`professionalAddress${index}`);
      else
        this.removeError(`professionalAddress${index}`);
    },
    validateQualification (index) {
      this.validateNotEmpty(`qualification${index}`, this.model[index].qualification);
    },
    validateStartDate (index) {
      if (!this.model[index].startDate)
        this.addError(`startDate${index}`);
      else
        this.removeError(`startDate${index}`);
    },
    validate () {
      this.model.forEach((beneficiary, index) => {
        this.validateAddress(index);
        this.validateBirthdate(index);
        this.validateFirstname(index);
        this.validateLastname(index);
        this.validateProfessionalAddress(index);
        this.validateQualification(index);
        this.validateStartDate(index);
      });
    }
  }
};
</script>
